<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Nuevo usuario</p>
        <button class="delete" aria-label="close" @click="close"></button>
      </header>
      <section class="modal-card-body">
        <div class="columns is-multiline">
          <div class="column is-4">
            <div class="field">
              <label class="label is-mandatory">Nombre</label>
              <div class="control">
                <input
                  v-model="name"
                  class="input"
                  v-validate="'required'"
                  name="name"
                  type="text"
                  data-vv-as="nombre"
                >
                <span class="is-error-input">{{ vverrors.first('name') }}</span>
              </div>
            </div>
          </div>
          <div class="column is-4">
            <div class="field">
              <label class="label is-mandatory">Primer apellido</label>
              <div class="control">
                <input
                  v-model="lastName"
                  class="input"
                  v-validate="'required'"
                  name="lastName"
                  type="text"
                  data-vv-as="primer apellido"
                >
                <span class="is-error-input">{{ vverrors.first('lastName') }}</span>
              </div>
            </div>
          </div>
          <div class="column is-4">
            <div class="field">
              <label class="label is-mandatory">Segundo apellido</label>
              <div class="control">
                <input
                  v-model="secondLastName"
                  class="input"
                  v-validate="'required'"
                  name="secondLastName"
                  type="text"
                  data-vv-as="segundo apellido"
                >
                <span class="is-error-input">{{ vverrors.first('secondLastName') }}</span>
              </div>
            </div>
          </div>
          <div class="column is-4">
            <div class="field">
              <label class="label is-mandatory">Coreo electrónico</label>
              <div class="control">
                <input
                  v-model="email"
                  v-validate="'required|email'"
                  name="email"
                  class="input"
                  type="text"
                  data-vv-as="email"
                >
                <span class="is-error-input">{{ vverrors.first('email') }}</span>
              </div>
            </div>
          </div>
          <div class="column is-12">
            <hr class="divider" />
          </div>
          <div class="column is-4">
            <div class="field">
              <label class="label is-mandatory">Contraseña</label>
              <div class="control">
                <input
                  v-model="password"
                  class="input"
                  v-validate="'required'"
                  name="password"
                  type="text"
                  data-vv-as="contraseña"
                  ref="password"
                >
                <span class="is-error-input">{{ vverrors.first('password') }}</span>
              </div>
            </div>
          </div>
          <div class="column is-4">
            <div class="field">
              <label class="label is-mandatory">Confirma la contraseña</label>
              <div class="control">
                <input
                  v-model="confirmPassword"
                  class="input"
                  v-validate="'required|confirmed:password'"
                  name="confirmPassword"
                  type="text"
                  data-vv-as="confirmar la contraseña"
                >
                <span class="is-error-input">{{ vverrors.first('confirmPassword') }}</span>
              </div>
            </div>
          </div>
          <div class="column is-12" v-if="error">
            <span class="is-error-input">{{ message }}</span>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-small" :disabled="loading" @click="close">Cancelar</button>
        <button
          class="button is-active-b is-small"
          @click="saveUsuario"
          :disabled="loading"
          :class="{'is-loading': loading}"
        >
          Guardar
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import api from '@/libs/api'
export default {
  name: 'ModalNuevoUsuario',
  props: {
    updateList: {
      type: Function,
      default: () => {}
    },
    close: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      name: null,
      lastName: null,
      secondLastName: null,
      email: null,
      status: null,
      password: null,
      confirmPassword: null,
      loading: false,
      error: false,
      message: null
    }
  },
  methods: {
    async saveUsuario () {
      this.error = false
      if (await this.$validator.validateAll()) {
        this.loading = true
        const data = {
          name: this.name,
          lastName: this.lastName,
          secondLastName: this.secondLastName,
          email: this.email,
          password: this.password
        }
        const response = await api.saveUsuario(data)
        if (response.success) {
          this.updateList(1)
          this.close()
        } else {
          console.log('entra', response)
          this.error = true
          this.message = response.message
        }
        this.loading = false
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.divider {
  margin: 0.5rem 0;
}
.field {
  .label {
    font-size: 13px;
    text-align: left;
    margin-left: 0.2rem;
    font-weight: 500;
  }
}
</style>
